<template>
  <div id="va-input">
    <v-text-field
      class="va-form-control"
      :counter="counter"
      :disabled="disabled"
      :append-icon="appendIcon"
      :error="error"
      :error-messages="errorMessages"
      :height="height"
      :loading="loading"
      :messages="messages"
      :placeholder="placeholder"
      :persistent-hint="persistentHint"
      :hint="hint"
      :hide-details="hideDetails"
      :label="label"
      :prepend-icon="prependIcon"
      :readonly="readonly"
      :rules="rules"
      :success="success"
      :success-messages="successMessages"
      :validate-on-blur="validateOnBlur"
  
      :type="type"
      :clearable="clearable"
      :autofocus="autofocus"
      :clear-icon="clearIcon"
      :dont-fill-mask-blanks="dontFillMaskBlanks"
      :suffix="suffix"
      :prefix="prefix"
      :mask="mask"
      :single-line='singleLine'

      :style="{width: width || '100%', 'max-width': maxWidth || 'auto'}"
      
     
      v-bind="attrs"
      :value="value"
      :name="name"
      @input="input"
      @blur="blur"
      @focus="focus"
    ></v-text-field>

  </div>
</template>

<script scoped>

export default {
  name:'va-input',
  props: [
    'type',
    'clearable',
    'autofocus',
    'clearIcon',
    'dontFillMaskBlanks',
    'mask',
    'singleLine',
    'prefix',
    'suffix',
    'name',
    
    'appendIcon', 'width', 'maxWidth', 'counter', 'disabled', 'error', 'errorMessages', 'height', 'loading', 'hideDetails', 'hint', 'label', 'messages','placeholder', 'persistentHint', 'prependIcon', 'readonly', 'rules', 'success', 'successMessages', 'validateOnBlur','value' 
  ],
  inheritAttrs: false,
  computed: {
    listeners() {
      const {...listeners } = this.$listeners
      return listeners
    },
    attrs() {
      const {...attrs } = this.$attrs
      return attrs
    },
  },
  methods: {
    input(event) {
      this.$emit('input', event)
    },
    blur(event) {
      this.$emit('blur', event)
    },
    focus(event) {
      this.$emit('focus', event)
    },
  },

  
}
</script>
<style scoped>
/** va input style **/
.va-form-control >>> input{
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: block;
	width: 100%;
  border-style: solid !important;
	border-width: 2px !important;
  margin-top: 7px;
	-webkit-transition: background-color .2s ease-in-out,border-color .2s ease-in-out;
	transition: background-color .2s ease-in-out,border-color .2s ease-in-out;
	padding: 7px !important;
	font-size: 14px;
	line-height: 1.45em;
	border-radius: 4px;
	min-height: 36px;
	max-height: 36px;
  background: #F4F5F7 !important;
  color: #505f79 !important;
  border-color: #dfe1e5 !important;}

.va-form-control >>> .v-icon {
  font-size: 16px;}
.va-form-control >>> .v-input__prepend-outer {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  margin-top: 15px;
  margin-left: 7px;
  z-index: 2;
  line-height: 1;}
.va-form-control >>> .v-input__prepend-outer + .v-input__control input {
  padding-left: 32px !important;}

.va-form-control >>> .v-input__append-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  margin-top: 15px;
  margin-right: 7px;
  right: 0;
  z-index: 2;
  line-height: 1;}
.va-form-control >>> .v-input__append-outer ~ .v-input__control input {
  padding-right: 32px !important;}


/** va input label styles **/
.va-form-control >>> label {
  color: #505f79 !important;}
.va-form-control >>>  .v-input__slot::before {
   bottom: 1px;}
.va-form-control >>>  .v-input__slot::after {
   bottom: 1px;}
.va-form-control >>> label.primary--text {
  font-weight: 400px;
  color: #505f79 !important;}
.va-form-control >>> label.error--text{
color: red !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;}

</style>
