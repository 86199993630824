import BranchService from '@/services/branch-service'
import moment from 'moment'

export default  {
  state : {
    branches: [],
    totalCount: 0,
    branch: {
      ID: '',
      branch_code: '',
      branch_name:'',
      branch_email:'',
      branch_scope: 'Branch',
      branch_telephone:'',
      branch_street:'',
      branch_city:'',
      branch_province:'',
      branch_country:'',
      branch_risk_factor_group: '-',
      branch_timezone:'',
    },
    message: {},
    isLoading: false
  },

  getters : {
  
  },

  mutations : {
    SET_BRANCHES(state, {data, total}) {
      state.branches = data
      state.totalCount = total
    },
    ADD_BRANCH(state, obj) {
      state.branches.push(obj)
    },
    UPDATE_BRANCH(state, {index, obj}) {
      state.branches[index] = obj
    },
    DELETE_BRANCH(state,  index) {
      state.branches.splice(index, 1)
    },
    SET_ERROR_MESSAGE(state, {type = 'error', title = '', content = ''}) {
      state.message = {...type, ...title, ...content}
    }
  },

  actions : {
    async getBranches ({state, commit}, obj) {
      try{
        state.isLoading = true
        let response = await BranchService.findAll(obj.filter, obj.sort, obj.paginate)
        if(response) {
          response.data.forEach((el)=> {
            Object.keys(el).forEach((key)=>{
              el[key] = el[key] == 'false' || el[key] == 'undefined' || el[key] == 'null' ? false : el[key]
            })
          })
          commit('SET_BRANCHES',{data: response.data, total: response.total})
        }
      }catch(e){
       throw e
      } finally {
        state.isLoading = false
      }
    },

    async createBranch({state, commit}, obj) {
      try {
        state.isLoading = true
        let response = await BranchService.create(obj)
        if(response) {
          obj.ID = response.data
          obj.is_active = true
          obj.date_created = moment().utc().format('YYYY-MM-DD HH:mm:ss')
          commit('ADD_BRANCH', obj)
        }
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },

    async updateBranch({state, commit}, {index, obj}) {
      try {
        state.isLoading = true
        let updatedObj = Object.assign({}, obj)
        delete updatedObj['date_created']
        delete updatedObj['date_updated']
        
        let response = await BranchService.update(updatedObj.ID, updatedObj)
        if(response) {
          commit('UPDATE_BRANCH', {index, obj})
        }
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },

    async deleteBranch({state, commit}, index) {
      try {
        state.isLoading = true
        let id = state.branches[index].ID
        let response = await BranchService.delete(id)
        if(response) {
          commit('DELETE_BRANCH', id)
        }
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },
  }
}