import RiskFactorService from '@/services/risk-factor-service'
import moment from 'moment'

export default  {
  state : {
    riskfactors: [],
    riskFactorGroups: [],
    totalCount: 0,
    message: {},
    isLoading: false, 
    defaultSubRiskFactor: {
      ID: '',
      sub_risk_factor_name                  : '',
      sub_risk_factor_score                 : 0.00,
      amount                                : 0.00,
      tenure                                : 0.00,
      interest                              : 0.00,
      shares                                : 0.00,
      dsr                                   : 0.00,
      value_collateral_used              : 0.00,
      sub_risk_factor_adjustment_is_active  : true,
      sub_risk_factor_is_active             : true,
    },
    defaultRangeOptions: {
      min: 0,
      rows: 2,
      interval: 1,
      default_risk_score: 0,
      option: 'merge',
      amount   : 0.00,
      tenure   : 0.00,
      interest : 0.00,
      shares   : 0.00,
      dsr      : 0.00

    },
    defaultGroup: {
      group_name: "",
      factors: [],
    }
  },
 
  getters : {
  
  },

  mutations : {
    ADD_RISK_FACTOR_GROUP(state, obj) {
      state.riskFactorGroups.push(obj)
    },
    SET_RISK_FACTORS(state, {records, total}) {
      state.riskfactors = records
      state.totalCount = total
    },
    SET_SUB_RISK_FACTOR(state, {selectedFactor, subs}) {
      state.riskfactors.forEach((el) => {
        if(el.ID === selectedFactor.ID)
          el.sub_risk_factors = subs

      })
    },
    SET_RISK_FACTOR_GROUP(state, groups) {
      state.riskFactorGroups = groups
    },
    SET_ERROR_MESSAGE(state, {type = 'error', title = '', content = ''}) {
      state.message = {...type, ...title, ...content}
    },
    UPDATE_RISK_FACTOR(state,{index, factor}) {
      state.riskfactors[index] = factor
    },
    UPDATE_SUB_RISK_FACTOR(state, {selectedFactor, subIndex, sub}) {
      state.riskfactors.forEach((el) => {
        if(el.ID === selectedFactor.ID)
          el.sub_risk_factors[subIndex] = sub
      })
    },
    UPDATE_RISK_FACTOR_GROUP(state, {index, group}) {
      state.riskFactorGroups[index] = group
    },
    DELETE_SUB_RISK_FACTOR(state, {selectedFactor, index}) {
      state.riskfactors.forEach((el) => {
        if(el.ID === selectedFactor.ID)
          el.sub_risk_factors.splice(index, 1)
      })
    },
    DELETE_RISK_FACTOR_GROUP(state, index) {
      state.riskFactorGroups.splice(index, 1)
    },
  },

  actions : {
    async getRiskFactors ({state, commit}) {
      try{
        state.isLoading = true
        let response = await RiskFactorService.findAll()
        if(response) {
          response.data.forEach((el)=> {
            
            Object.keys(el).forEach((key)=>{
              el[key] = el[key] == 'false' || el[key] == 'undefined' || el[key] == 'null' ? false : el[key]
            })
            el.sub_risk_factors.forEach((elx)=> {
              elx.sub_risk_factor_score = (Number(elx.sub_risk_factor_score) * 100).toFixed(2)
              elx.amount = (Number(elx.amount) * 100).toFixed(2)
              elx.tenure = (Number(elx.tenure) * 100).toFixed(2)
              elx.interest = (Number(elx.interest) * 100).toFixed(2)
              elx.shares = (Number(elx.shares) * 100).toFixed(2)
              elx.dsr = (Number(elx.dsr) * 100).toFixed(2)
              elx.value_collateral_used = (Number(elx.value_collateral_used) * 100).toFixed(2)
              Object.keys(elx).forEach((key)=>{
                elx[key] = elx[key] == 'false' || elx[key] == 'undefined' || elx[key] == 'null' ? false : elx[key]
              })
            })
          })
          commit('SET_RISK_FACTORS',{ records: response.data, total: response.total})
        }
      }catch(e){
       throw e
      } finally {
        state.isLoading = false
      }
    },

    async getRiskFactorGroups ({state, commit}) {
      try{
        state.isLoading = true
        let response = await RiskFactorService.findAllGroups()
        if(response) {
          response.data.forEach((el)=> {
            el.factors.forEach((elx)=> {
              elx.sub_risk_factors.forEach((elxx)=> {
                elxx.sub_risk_factor_score = (Number(elxx.sub_risk_factor_score) * 100).toFixed(2)
                elxx.amount = (Number(elxx.amount) * 100).toFixed(2)
                elxx.tenure = (Number(elxx.tenure) * 100).toFixed(2)
                elxx.interest = (Number(elxx.interest) * 100).toFixed(2)
                elxx.shares = (Number(elxx.shares) * 100).toFixed(2)
                elxx.dsr = (Number(elxx.dsr) * 100).toFixed(2)
                elxx.value_collateral_used = (Number(elxx.value_collateral_used) * 100).toFixed(2)
                Object.keys(elxx).forEach((key)=>{
                  elxx[key] = elxx[key] == 'false' || elxx[key] == 'undefined' || elxx[key] == 'null' ? false : elxx[key]
                })
              })
            })
          })
          commit('SET_RISK_FACTOR_GROUP',response.data)
        }
      }catch(e){
       throw e
      } finally {
        state.isLoading = false
      }
    },

    async createSubFactor({state, commit}, {selectedFactor, factorIndex, objs}) {
      try {
        state.isLoading = true
        let factor = state.riskfactors.find((el) => selectedFactor.ID === el.ID )
        
        for(let i = 0; i < objs.length; i++) {
          objs[i].sub_risk_factor_score = (Number(objs[i].sub_risk_factor_score) / 100).toFixed(2)
          objs[i].amount = (Number(objs[i].amount) / 100).toFixed(2)
          objs[i].tenure = (Number(objs[i].tenure) / 100).toFixed(2)
          objs[i].interest = (Number(objs[i].interest) / 100).toFixed(2)
          objs[i].shares = (Number(objs[i].shares) / 100).toFixed(2)
          objs[i].dsr = (Number(objs[i].dsr) / 100).toFixed(2)
          objs[i].value_collateral_used = (Number(objs[i].value_collateral_used) / 100).toFixed(2)
          delete objs[i]['date_created']
          delete objs[i]['date_updated']
        }
        
        let response = await RiskFactorService.createSubRiskFactors(factor.ID, objs)
        if(response) {
          response.data.forEach((res, i)=>{
            objs[i].ID = res
            objs[i].sub_risk_factor_score = (Number(objs[i].sub_risk_factor_score) * 100).toFixed(2)
            objs[i].amount = (Number(objs[i].amount) / 100).toFixed(2)
            objs[i].tenure = (Number(objs[i].tenure) / 100).toFixed(2)
            objs[i].interest = (Number(objs[i].interest) / 100).toFixed(2)
            objs[i].shares = (Number(objs[i].shares) / 100).toFixed(2)
            objs[i].dsr = (Number(objs[i].dsr) / 100).toFixed(2)
            objs[i].value_collateral_used = (Number(objs[i].value_collateral_used) / 100).toFixed(2)
            objs[i].date_created = moment().utc().format('YYYY-MM-DD HH:mm:ss')
          })
          commit('SET_SUB_RISK_FACTOR',{selectedFactor: selectedFactor, factorIndex: factorIndex , subs: objs})
        }
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },


    async createRiskFactorGroup({state, commit}, obj) {
      try {
        state.isLoading = true        
        let newObj = {...obj}

        newObj.factors.forEach((el)=> {
          el.sub_risk_factors.forEach((elx)=> {
            elx.sub_risk_factor_score = (Number(elx.sub_risk_factor_score) / 100).toFixed(2)
            elx.amount = (Number(elx.amount) / 100).toFixed(2)
            elx.tenure = (Number(elx.tenure) / 100).toFixed(2)
            elx.interest = (Number(elx.interest) / 100).toFixed(2)
            elx.shares = (Number(elx.shares) / 100).toFixed(2)
            elx.dsr = (Number(elx.dsr) / 100).toFixed(2)
            elx.value_collateral_used = (Number(elx.value_collateral_used) / 100).toFixed(2)
          })
        })

        let response = await RiskFactorService.createRiskFactorGroup(newObj)
        if(response) {
          obj.ID = response.data
          commit('ADD_RISK_FACTOR_GROUP', obj)
        }
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },

    async updateRiskFactor({state, commit}, {index, obj}) {
      try {
        state.isLoading = true
        let updatedObj = Object.assign({}, obj)
        delete updatedObj['date_created']
        delete updatedObj['date_updated']
        delete updatedObj['sub_risk_factors']
        delete updatedObj['ID']
        delete updatedObj['sub_risk_factor_range']

        let response = await RiskFactorService.update(obj.ID, updatedObj)
        if(response) {
          commit('UPDATE_RISK_FACTOR', index, obj)
        }
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },


    async updateSubRiskFactor({state, commit}, {selectedFactor, subIndex, obj}) {
      try {
        state.isLoading = true
        let updatedObj = Object.assign({}, obj)
        delete updatedObj['date_created']
        delete updatedObj['date_updated']
        delete updatedObj['is_active']
        delete updatedObj['sub_risk_factors']

        updatedObj.sub_risk_factor_score = (updatedObj.sub_risk_factor_score / 100).toFixed(2)
        updatedObj.amount = (Number(updatedObj.amount) / 100).toFixed(2)
        updatedObj.tenure = (Number(updatedObj.tenure) / 100).toFixed(2)
        updatedObj.interest = (Number(updatedObj.interest) / 100).toFixed(2)
        updatedObj.shares = (Number(updatedObj.shares) / 100).toFixed(2)
        updatedObj.dsr = (Number(updatedObj.dsr) / 100).toFixed(2)
        updatedObj.value_collateral_used = (Number(updatedObj.value_collateral_used) / 100).toFixed(2)

        await RiskFactorService.updateSubFactor(obj.ID, updatedObj)

        commit('UPDATE_SUB_RISK_FACTOR', {selectedFactor: selectedFactor, subIndex: subIndex, sub: obj})
        
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },

    async updateRiskFactorGroup({state, commit}, {index, group}) {
      try {
        state.isLoading = true
        let updatedObj = {...group}
        delete updatedObj['ID']
        delete updatedObj['date_created']
        delete updatedObj['date_updated']

        updatedObj.factors.forEach((el)=> {
          el.sub_risk_factors.forEach((elx)=> {
            elx.sub_risk_factor_score = (Number(elx.sub_risk_factor_score) / 100).toFixed(2)
            elx.amount = (Number(elx.amount) / 100).toFixed(2)
            elx.tenure = (Number(elx.tenure) / 100).toFixed(2)
            elx.interest = (Number(elx.interest) / 100).toFixed(2)
            elx.shares = (Number(elx.shares) / 100).toFixed(2)
            elx.dsr = (Number(elx.dsr) / 100).toFixed(2)
            elx.value_collateral_used = (Number(elx.value_collateral_used) / 100).toFixed(2)
          })
        })
        await RiskFactorService.updateFactorGroup(group.ID, updatedObj)
        group.factors.forEach((el)=> {
          el.sub_risk_factors.forEach((elx)=> {
            elx.sub_risk_factor_score = (Number(elx.sub_risk_factor_score) * 100).toFixed(2)
            elx.amount = (Number(elx.amount) * 100).toFixed(2)
            elx.tenure = (Number(elx.tenure) * 100).toFixed(2)
            elx.interest = (Number(elx.interest) * 100).toFixed(2)
            elx.shares = (Number(elx.shares) * 100).toFixed(2)
            elx.dsr = (Number(elx.dsr) * 100).toFixed(2)
            elx.value_collateral_used = (Number(elx.value_collateral_used) * 100).toFixed(2)
          })
        })
        commit('UPDATE_RISK_FACTOR_GROUP', {index: index, group: group})
        
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },

    async deleteSubFactor({state, commit}, {selectedFactor, index}) {
      try {
        state.isLoading = true
        let id = null
        state.riskfactors.forEach((el) => {
          if(el.ID === selectedFactor.ID)
            id = el.sub_risk_factors[index].ID
        })
        await RiskFactorService.deleteSubFactor(id)
        
        commit('DELETE_SUB_RISK_FACTOR', {selectedFactor: selectedFactor, index:index})
        
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },

    async deleteFactorGroup({state, commit}, index) {
      try {
        state.isLoading = true
        let id = state.riskFactorGroups[index].ID
        await RiskFactorService.deleteFactorGroup(id)
        
        commit('DELETE_RISK_FACTOR_GROUP', index)
        
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },
  }
}