<template>
  <div id="va-select">
    <v-select
      class="va-form-control"
      :items="items"
      :counter="counter"
      :disabled="disabled"
      :append-icon="appendIcon"
      :error="error"
      :error-messages="errorMessages"
      :height="height"
      :loading="loading"
      :messages="messages"
      :placeholder="placeholder"
      :persistent-hint="persistentHint"
      :hint="hint"
      :hide-details="hideDetails"
      :label="label"
      :prepend-icon="prependIcon"
      :readonly="readonly"
      :rules="rules"
      :success="success"
      :success-messages="successMessages"
      :validate-on-blur="validateOnBlur"
      :value="value"

      :type="type"
      :clearable="clearable"
      :autofocus="autofocus"
      :clear-icon="clearIcon"
      :dont-fill-mask-blanks="dontFillMaskBlanks"
      :suffix="suffix"
      :prefix="prefix"
      :mask="mask"
      :single-line='singleLine'

      :name="name"
      :no-data-text="noDataText"
      :open-on-clear="openOnClear"
      :return-masked-value="returnMaskedValue"
      :value-comparator="valueComparator"
      :small-chips="smallChips"
      :multiple="multiple"
      :menu-props="menuProps"
      :item-text="itemText"
      :item-avatar="itemAvatar"
      :item-disabled="itemDisabled"
      :item-value="itemValue"
      :hide-selected="hideSelected"
      :filter="filter"
      :dense="dense"
      :chips="chips"
      :cache-items="cacheItems"
      :browser-autocomplete="browserAutocomplete"
      :attach="attach"

      :style="{width: width || '100%', 'max-width': maxWidth || 'auto'}"
      @input="input"
      @blur="blur"
      v-on="listeners"
      v-bind="attrs"
    ></v-select>

  </div>
</template>

<script scoped>

export default {
  name:'va-select',
  props: [
    'noDataText',
    'openOnClear',
    'returnMaskedValue',
    'valueComparator',
    'smallChips',
    'multiple',
    'menuProps',
    'itemText',
    'itemAvatar',
    'itemDisabled',
    'itemValue',
    'hideSelected',
    'filter',
    'dense',
    'chips',
    'cacheItems',
    'browserAutocomplete',
    'attach',
    'items',

    'type',
    'clearable',
    'autofocus',
    'clearIcon',
    'dontFillMaskBlanks',
    'mask',
    'singleLine',
    'prefix',
    'suffix',
    'name',
    
    'width', 'maxWidth', 'appendIcon', 'counter', 'disabled', 'error', 'errorMessages', 'height', 'loading', 'hideDetails', 'hint', 'label', 'messages','placeholder', 'persistentHint', 'prependIcon', 'readonly', 'rules', 'success', 'successMessages', 'validateOnBlur','value' ],
  inheritAttrs: false,
  computed: {
    listeners() {
      const { ...listeners } = this.$listeners
      return listeners
    },
    attrs() {
      const { ...attrs } = this.$attrs
      return attrs;
    },
  },
  methods: {
    input(event) {
      this.$emit('input', event)
    },
    blur(event) {
      this.$emit('blur', event)
    },
  },

  
}
</script>
<style scoped>
/** va input style **/
.va-form-control >>> input{
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: block;
	width: 100%;
  border-style: solid !important;
	border-width: 2px !important;
  margin-top: 7px;
	-webkit-transition: background-color .2s ease-in-out,border-color .2s ease-in-out;
	transition: background-color .2s ease-in-out,border-color .2s ease-in-out;
	padding: 7px !important;
	font-size: 14px;
	line-height: 1.45em;
	border-radius: 4px;
	min-height: 36px;
	max-height: 36px;
  background: #F4F5F7 !important;
  color: #505f79 !important;
  border-color: #dfe1e5 !important;}

.va-form-control >>> .v-icon {
  font-size: 16px;}
.va-form-control >>> .v-input__prepend-outer {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  margin-top: 15px;
  margin-left: 7px;
  z-index: 2;
  line-height: 1;}
.va-form-control >>> .v-input__prepend-outer + .v-input__control input {
  padding-left: 32px !important;}

.va-form-control >>> .v-input__append-inner {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  margin-top: 15px;
  margin-right: 7px;
  right: 0;
  z-index: 2;
  line-height: 1;}
.va-form-control >>> .v-input__append-outer ~ .v-input__control input {
  padding-right: 32px !important;}


/** va input label styles **/
.va-form-control >>> label {
  color: #505f79 !important;}
.va-form-control >>>  .v-input__slot::before {
   bottom: 1px;}
.va-form-control >>>  .v-input__slot::after {
   bottom: 1px;}
.va-form-control >>> label.primary--text {
  font-weight: 400px;
  color: #505f79 !important;}
.va-form-control >>> label.error--text{
  color: red !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;}

.va-form-control >>> .v-select__selection--comma {
    position: absolute;
    z-index: 2;
    margin-left: 10px;
    margin-top: 3px;
    width: 420px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
