import httpClient from '@/common/http'
export default {
  create(obj) {
    return httpClient.request('post', 'settings/loan_origination/loan_types', obj);
  },

  update(id, obj) {
    return httpClient.request('put', `settings/loan_origination/loan_types/${id}`, obj);
  },

  delete(id, replacement) {
    return httpClient.request('delete', `settings/loan_origination/loan_types/${id}/replacement/${replacement}`);
  },

  findOne(id) {
    return httpClient.request('get', `settings/loan_origination/loan_types/${id}`)
  },

  findAll(filter, sort, paginate = {skip: 0, limit: -1}) {
    let query = `${sort}`
    query += (paginate.limit < 1) ? '' : `&skip=${paginate.skip}&limit=${paginate.limit}`
    return httpClient.request('get', `settings/loan_origination/loan_types?${filter}${query}`)
  }

}