import httpClient from '@/common/http'
export default {

  batchSave(factors) {
    return httpClient.request('post', `/settings/risk_factors/batch`, factors)
  },

  createSubRiskFactors(factorID, objs) {
    return httpClient.request('post', `/settings/risk_factors/${factorID}/sub_factors`, objs)
  },

  createRiskFactorGroup(obj) {
    return httpClient.request('post', `/settings/risk_factor_groups`, obj)
  },

  update(id, obj) {
    return httpClient.request('put', `/settings/risk_factors/${id}`, obj)
  },

  updateSubFactor(id, obj) {
    return httpClient.request('put', `/settings/risk_factors/sub_factors/${id}`, obj)
  },

  updateFactorGroup(id, obj) {
    return httpClient.request('put', `/settings/risk_factor_groups/${id}`, obj)
  },

  deleteSubFactor(id) {
    return httpClient.request('delete', `/settings/risk_factors/sub_factors/${id}`)
  },

  deleteFactorGroup(id) {
    return httpClient.request('delete', `/settings/risk_factor_groups/${id}`)
  },

  findOne(id) {
    return httpClient.request('get', `/settings/risk_factors/${id}`)
  },

  findAll(filter) {
    return httpClient.request('get', `/settings/risk_factors`+(filter? '?'+filter : ''))
  },

  findAllGroups(filter) {
    return httpClient.request('get', `/settings/risk_factor_groups`+(filter? '?'+filter : ''))
  }

}