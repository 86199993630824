
export default {
  path: 'transaction-monitoring-settings',
  name: 'transaction-monitoring-settings',
  component: () => import(/* webpackChunkName: "login" */ '@/views/settings/transaction-monitoring-settings/Transaction-Monitoring-Settings-Layout.vue'),
  redirect: {
    name: "transaction-general"
  },
  children: [
    {
      path: 'transaction-general',
      name: 'transaction-general',
      component: () => import(/* webpackChunkName: "login" */ '@/views/settings/account-management-settings/Account-General-Settings.vue')
    },
  ]
}