import LoanApprovalService from '@/services/loan-approval-policy.service'

export default {
    state: {
        loading: false,
        settings: [],
        levels: []
    },
    
    getters: {
        getAllLevels(state) {
            let results = state.levels.map(res => res.approval_level != null)
            return results
        }
    },

    mutations: {
        ADD_APPROVAL_LEVEL(state, obj) {
            state.settings = obj
            state.levels.push(obj.approval_level)
        },

        FIND_SETTINGS(state, obj) {
            state.settings = obj
        },

        FIND_ALL_LEVELS(state, obj) {
            state.levels = obj
        }
    },

    actions: {
        async createApprovalLevel({commit, state}, obj) {
            try {
                state.loading = true
                let response = await LoanApprovalService.create(obj)
                if(response && !response.found) {
                    commit('ADD_APPROVAL_LEVEL', response)
                    return true
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async saveAmount({ state}, obj) {
            try {
                state.loading = true
                let response = await LoanApprovalService.updateAmount(obj)
                if(response) {
                    return true
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async saveLevelActive({state}, obj) {
            try {
                let response = await LoanApprovalService.updateLevelActive(obj)
                if(response) {
                    return true
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async saveLoanTypeActive({state}, obj) {
            try {
                let response = await LoanApprovalService.updateLoanTypeActive(obj)
                if(response) {
                    return true
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async findSettings({state, commit}, level) {
            try {
                state.loading = true
                let response = await LoanApprovalService.findOne(level)
                if(response) {
                    commit('FIND_SETTINGS', response)
                    return true
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async findAllLevels({state, commit}) {
            try {
                state.loading = true
                let response = await LoanApprovalService.findAll()
                if(response[0].length > 0) {
                    commit('FIND_ALL_LEVELS', response)
                    return true
                }
                else {
                    return false
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        }
    }
}