import httpClient from '@/common/http'
export default {
  async update(obj) {
    return await httpClient.request('patch', `settings/loan_origination/climate-risk-ratings/${obj.id}/${obj.type}`, obj.body);
  },

  async findAll() {
    return await httpClient.request('get', `settings/loan_origination/climate-risk-ratings`);
  },
  async updateGlobal(obj) {
    return await httpClient.request('put', `settings/loan_origination/climate-risk-ratings/global/${obj.id}`, obj.body);
  },

  async findAllGlobal() {
    return await httpClient.request('get', `settings/loan_origination/climate-risk-ratings/global`);
  },

}