
export default {
    path: 'loan-origination-settings',
    name: 'loan-origination-settings',
    component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Loan-Origination-Settings-Layout.vue'),
    redirect: {
      name: "loan-general"
    },
    children: [
      {
        path: 'loan-general',
        name: 'loan-general',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Loan-General-Settings.vue')
      },
      {
        path: 'loan-fees',
        name: 'loan-fees',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Loan-Fee-Settings.vue')
      },
      {
        path: 'loan-types',
        name: 'loan-types',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Loan-Type-Settings.vue')
      },
      {
        path:'loan-products',
        name:'loan-products',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Loan-Product-Settings.vue'),
      },
      {
        path:'loan-accounts',
        name:'loan-accounts',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Loan-Account-Types.vue'),
      },
      {
        path:'loan-checklist-settings',
        name:'loan-checklist-settings',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Loan-Checklist-Settings.vue'),
      },
      {
        path:'loan-waiver',
        name:'loan-waiver',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Waiver-Policy-Settings.vue'),
      },
      {
        path:'loan-approval',
        name:'loan-approval',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Loan-Approval-Policy-Settings.vue'),
      },
      {
        path: 'collateral-types',
        name: 'collateral-types',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Collateral-Type-Settings.vue')
      },
      {
        path: 'yapu-management',
        name: 'yapu-management',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/Yapu-Management.vue')
      },
      {
        path: 'dsr-calculation-methods',
        name: 'dsr-calculation-methods',
        component: () => import(/* webpackChunkName: "login" */ '@/views/settings/loan-origination-settings/DSR-Calculation-Method-Settings.vue')
      },
    ]
}