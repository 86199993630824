import LoanTypeService from '@/services/loan-type-service'
import moment from 'moment'
import _ from 'lodash'
export default  {
  state : {
    loanTypes: [],
    totalCount: 0,
    loanType: {
      ID: '',
      loan_type_name:'',
      loan_type_category: '',
      loan_type_fees: [],
      loan_type_checklist: [],
    },
    message: {},
    isLoading: false
  },

  getters : {
  
  },

  mutations : {
    SET_LOAN_TYPES(state, {data, total}) {
      state.loanTypes = data
      state.totalCount = total
    },
    ADD_LOAN_TYPE(state, obj) {
      state.loanTypes.push(obj)
    },
    UPDATE_LOAN_TYPE(state, {index,loanType}) {
     state.loanTypes.splice(index, 1, loanType)
    },
    DELETE_LOAN_TYPE(state,  index) {
      state.loanTypes.splice(index, 1)
    },
    SET_ERROR_MESSAGE(state, {type = 'error', title = '', content = ''}) {
      state.message = {...type, ...title, ...content}
    }
  },

  actions : {
    async getLoanTypes ({state, commit}, obj) {
      try{
        state.isLoading = true
        let response = await LoanTypeService.findAll(obj.filter, obj.sort, obj.paginate)
        if(response) {
          response.data.forEach((el)=> {
            Object.keys(el).forEach((key)=>{
              el[key] = el[key] == 'false' || el[key] == 'undefined' || el[key] == 'null' ? false : el[key]
            })
          })
          commit('SET_LOAN_TYPES', {data: response.data, total: response.total})
        }
      }catch(e){
       throw e
      } finally {
        state.isLoading = false
      }
    },

    async createLoanType({state, commit}, obj) {
      try {
        state.isLoading = true
        let newObj = _.cloneDeep(obj)
        let selectedItems = obj.loan_type_checklist.filter(el=> el.is_selected )
        let selectedFees = obj.loan_type_fees.filter(el=> el.is_selected )

        obj.loan_type_checklist = selectedItems
        obj.loan_type_fees = selectedFees

        newObj.loan_type_checklist = selectedItems.map((el)=> el.ID)
        newObj.loan_type_fees = selectedFees.map((el)=> el.ID)

        let response = await LoanTypeService.create(newObj)
        if(response) {
          obj.ID = response.data
          obj.date_created = moment().utc().format('YYYY-MM-DD HH:mm:ss')
          commit('ADD_LOAN_TYPE', obj)
        }
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },

    async updateLoanType({state, commit}, {index, obj}) {
      try {
        state.isLoading = true
        let updatedObj = _.cloneDeep(obj)
        delete updatedObj['ID']
        delete updatedObj['date_created']
        delete updatedObj['date_updated']
        delete updatedObj['is_active']
        
        let selectedItems = obj.loan_type_checklist.filter(el=> el.is_selected )
        let selectedFees = obj.loan_type_fees.filter(el=> el.is_selected )

        obj.loan_type_checklist = selectedItems
        obj.loan_type_fees = selectedFees

        updatedObj.loan_type_checklist = selectedItems.map((el)=> el.ID)
        updatedObj.loan_type_fees = selectedFees.map((el)=> el.ID)

        await LoanTypeService.update(obj.ID, updatedObj)
        commit('UPDATE_LOAN_TYPE', {index:index, loanType: obj})
        
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },

    async deleteLoanType({state, commit}, {index, replacementID}) {
      try {
        state.isLoading = true
        let id = state.loanTypes[index].ID
        await LoanTypeService.delete(id, replacementID)
        commit('DELETE_LOAN_TYPE', index)
        
      } catch(e) {
        throw e
      } finally {
        state.isLoading = false
      }
    },
  }
}