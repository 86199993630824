export default {
  state: {
    showSidebarMenuControl: false,
    showSidebarModel: true,
    sidebarRoutes: [],
    sidebarMini: false,
    defaultPageAlert: {
      visible: false,
      type: 'danger',
      title: 'An unexpected error has occured.',
      content: 'Please check your internet connection. If problem persist contact administrator.'
    },
    pageAlert : {
      visible: false,
      type: 'error',
      title: '',
      content: ''
    },
  },

  mutations: {
    SET_SIDEBAR_ROUTES(state, routes) {
      state.sidebarRoutes = routes
    }, 

    TOGGLE_SIDEBAR_VISIBILITY(state, show ) {
      state.showSidebarMenuControl = show
      state.sidebarMini = !show
    },
    
    TOGGLE_SIDEBAR_MINI(state) {
      state.sidebarMini = !state.sidebarMini
    },
    HIDE_SIDEBAR_MINI(state) {
      state.sidebarMini = false
    },
    SET_PAGE_ALERT(state, options) {
      state.pageAlert = options || state.defaultPageAlert
    },
    
    SHOW_UNEXPECTED_ERROR_DIALOG(state, value) {
      state.pageAlert = {
        visible: value,
        type: 'error',
        title: 'An unexpected error has occured.',
        content: 'There seems to be an unexpected error. Please check your internet connectivity and try again. If problem persist, Please contact your administrator..'
      }
    }
  }
  
}