import LoanWaiverService from '@/services/loan-waiver-policy-setting.service.js'

export default {
    state: {
        loading: false,
        settings: [],
        levels: [],
        insurance:[],
        dsr:[],
    },
    
    getters: {
        getAllWaiverLevels(state) {
            let results = state.levels.map(res => res.waiver_level != null)
            return results
        },
        getAllInsuranceLevels(state) {
            let results = state;
            return results
        },
        getAllDSRLevels(state) {
            let results = state;
            return results
        }
    },

    mutations: {
        ADD_WAIVER_LEVEL(state, obj) {
            state.settings = obj
            state.levels.push(obj.waiver_level)
        },

        FIND_WAIVER_SETTINGS(state, obj) {
            state.settings = obj
        },

        FIND_ALL_WAIVER_LEVELS(state, obj) {
            state.levels = obj
        },
        FIND_ALL_INSURANCE_WAIVER(state, obj) {
            state.insurance = obj
        },
        FIND_ALL_DSR_WAIVER(state, obj) {
            state.dsr = obj
        },
    },

    actions: {
        async createWaiverLevel({commit, state}, obj) {
            try {
                state.loading = true
                let response = await LoanWaiverService.create(obj)
                if(response && !response.found) {
                    commit('ADD_WAIVER_LEVEL', response)
                    return true
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async saveWaiverAmount({ state}, obj) {
            try {
                state.loading = true
                let response = await LoanWaiverService.updateWaiverAmount(obj)
                if(response) {
                    return true
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async saveWaiverLevelActive({state}, obj) {
            try {
                let response = await LoanWaiverService.updateWaiverLevelActive(obj)
                if(response) {
                    return true
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async saveLoanWaiverNameActive({state}, obj) {
            try {
                let response = await LoanWaiverService.updateLoanWaiverNameActive(obj)
                if(response) {
                    return true
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async findWaiverSettings({state, commit}, level) {
            try {
                state.loading = true
                let response = await LoanWaiverService.findOne(level)
                if(response) {
                    commit('FIND_WAIVER_SETTINGS', response)
                    return true
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async findAllWaiverLevels({state, commit}) {
            try {
                state.loading = true
                let response = await LoanWaiverService.findAll()
                if(response[0].length > 0) {
                    commit('FIND_ALL_WAIVER_LEVELS', response)
                    return true
                }
                else {
                    return false
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },

        // insurance waiver
        async addInsuranceWaiver({ state }, obj) {
            try {
                state.loading = true
                let response = await LoanWaiverService.addInsuranceWaiver(obj)
                if (response) {
                    return true
                }
            } catch (e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async editInsuranceWaiver({ state }, obj) {
            try {
                state.loading = true
                let response = await LoanWaiverService.editInsuranceWaiver(obj)
                if (response) {
                    return true
                }
            } catch (e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async getInsuranceWaiver({ state, commit }) {
            try {
                state.loading = true
                let response = await LoanWaiverService.getInsuranceWaiver()
                if (response.length > 0) {
                    commit('FIND_ALL_INSURANCE_WAIVER', response)
                    return true
                }
                else {
                    return false
                }
            } catch (e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async setInsuranceWaiverStatus({ state }, obj) {
            try {
                state.loading = true
                let response = await LoanWaiverService.setInsuranceWaiverStatus(obj)
                if (response) {
                    return true
                }
            } catch (e) {
                throw e
            } finally {
                state.loading = false
            }
        },

        // dsr waiver
        async addDSRWaiver({ state }, obj) {
            try {
                state.loading = true
                let response = await LoanWaiverService.addDSRWaiver(obj)
                if (response) {
                    return true
                }
            } catch (e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async editDSRWaiver({ state }, obj) {
            try {
                state.loading = true
                let response = await LoanWaiverService.editDSRWaiver(obj)
                if (response == true) {
                    return true
                }
            } catch (e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async getDSRWaiver({ state, commit }) {
            try {
                state.loading = true
                let response = await LoanWaiverService.getDSRWaiver();
                if (response.length > 0) {
                    commit('FIND_ALL_DSR_WAIVER', response)
                    return true
                }
                else {
                    return false
                }
            } catch (e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async setDSRWaiverStatus({ state }, obj) {
            try {
                state.loading = true
                let response = await LoanWaiverService.setDSRWaiverStatus(obj)
                if (response) {
                    return true
                }
            } catch (e) {
                throw e
            } finally {
                state.loading = false
            }
        },
    }
}