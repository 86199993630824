import httpClient from '@/common/http'
export default {
  create(loanTypeId, obj) {
    return httpClient.request('post', `settings/loan_origination/loan_types/${loanTypeId}/loan_products`, obj);
  },

  update(loanTypeId, id, obj) {
    return httpClient.request('put', `settings/loan_origination/loan_types/${loanTypeId}/loan_products/${id}`, obj);
  },

  changeIsActive(id, val) {
    return httpClient.request('put', `settings/loan_origination/loan_products/${id}/active/${val}`);
  },

  delete(id) {
    return httpClient.request('delete', `settings/loan_origination/loan_products/${id}`);
  },

  findOne(id) {
    return httpClient.request('get', `settings/loan_origination/loan_products/${id}`)
  },

  findAll(filter, sort, paginate = {skip: 0, limit: -1}) {
    let query = `${sort}`
    query += (paginate.limit < 1) ? '' : `&skip=${paginate.skip}&limit=${paginate.limit}`
    return httpClient.request('get', `settings/loan_origination/loan_products?${filter}${query}`)
  }

}