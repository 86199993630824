<template>
  <div id="index">
    <router-view></router-view>
  </div>
</template>

<style>

</style>

<script>
import { mapActions } from 'vuex'
export default {
  mounted() {
    this.init()
  },
 
  methods: {
    ...mapActions([
      'getCountries',
      'getCurrencies',
      'getTimezones'
    ]),

    async init() {
      try {
        this.getTimezones()
        await this.getCurrencies()
        await this.getCountries()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
