import httpClient from '@/common/http'
export default {
  setup(obj) {
    return httpClient.request('post', 'company/setup', obj)
  },

  verifySetupToken(token) {
    return httpClient.request('get', 'company/verify_token/'+token) 
  },

  update(obj) {
    return httpClient.request('put', 'company/', obj)
  },

  updateLogo(file) {
    let formData = new FormData()
    formData.append('logo', file)
    return httpClient.request('post', 'company/logo', formData, {'Content-Type': 'multipart/form-data'})
  },

  find() {
    return httpClient.request('get', 'company')
  },

  fetchModules() {
    return httpClient.request('get', 'company/apps')
  }
}